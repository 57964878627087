import { OutletDetails, OutletListItem } from '@ancon/wildcat-types'
import clsx from 'clsx'

import OutletDetailsOpeningHours from '../../../outlet/components/outletDetailsModal/OutletDetailsOpeningHours'
import OutletInformation from '../../../outlet/components/outletDetailsModal/OutletInformation'
import OutletCardOutletInfoSummary from '../outletCard/OutletCardOutletInfoSummary'
import OutletBusinessInformation from '../../../outlet/components/outletDetailsModal/OutletBusinessInformation'

import RestaurantDetailsSkeleton from './RestaurantDetailsSkeleton'
import RestaurantMap from './RestaurantMap'
import styles from './Restaurant.module.scss'

interface RestaurantProps {
  className?: string
  outletListItem?: OutletListItem | null
  outletDetails?: OutletDetails | null
}

export default function Restaurant({
  className,
  outletDetails,
  outletListItem,
}: RestaurantProps) {
  if (!outletDetails || !outletListItem) {
    return <RestaurantDetailsSkeleton />
  }

  const { businessNumber, legalName, latitude, longitude } = outletDetails

  return (
    <div className={clsx(styles.restaurantContainer, className)}>
      <div className={styles.restaurantHeader}>
        <OutletCardOutletInfoSummary outlet={outletListItem} />
      </div>
      <RestaurantMap coordinates={{ latitude, longitude }} />
      <OutletBusinessInformation
        className={styles.businessInfo}
        businessNumber={businessNumber}
        legalName={legalName}
      />
      <OutletDetailsOpeningHours outletListItem={outletListItem} />
      <OutletInformation
        outletDetails={outletDetails}
        outletListItem={outletListItem}
      />
    </div>
  )
}
