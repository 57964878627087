import { useCallback, useMemo } from 'react'
import { OrderFormat } from '@ancon/wildcat-types'
import moment from 'moment'
import EditIcon from '@ancon/wildcat-ui/shared/icons/edit-outline.svg'
import useTranslation from 'next-translate/useTranslation'
import StoreIcon from '@ancon/wildcat-ui/shared/icons/storefront-fill.svg'
import CalendarIcon from '@ancon/wildcat-ui/shared/icons/calendar-event-fill.svg'
import ClockIcon from '@ancon/wildcat-ui/shared/icons/clock-circle-fill.svg'
import HostIcon from '@ancon/wildcat-ui/shared/icons/host-user-fill.svg'
import UserGroupIcon from '@ancon/wildcat-ui/shared/icons/user-group-fill.svg'
import NormalOrderIcon from '@ancon/wildcat-ui/shared/icons/eat-in.svg'

import Button from '../../app/components/Button'
import OrderTypeIcon from '../../app/components/OrderTypeIcon'
import { PreOrderMethod } from '../types'
import getFormattedOrderTimeSlot from '../utils/getFormattedOrderTimeSlot'

import PreOrderIconDataField from './PreOrderIconDataField'
import styles from './PreOrderConfigurationOverview.module.scss'

enum PreOrderConfigField {
  Restaurant,
  OrderDate,
  OrderTime,
  OrderType,
  OrderMethod,
  Customer,
}

type DataMapper = {
  [P in PreOrderConfigField]?: any
}

type DataField = {
  field: PreOrderConfigField
  icon: React.ReactNode
  label: string
  value: string | null
}

interface PreOrderConfigurationOverviewProps {
  dataMapper: DataMapper
  editable?: boolean
  handleEdit?: (field: PreOrderConfigField) => void
}

const EditableFields = [
  PreOrderConfigField.OrderDate,
  PreOrderConfigField.OrderTime,
  PreOrderConfigField.OrderType,
  PreOrderConfigField.OrderMethod,
]

function PreOrderConfigurationOverview({
  dataMapper,
  editable,
  handleEdit,
}: PreOrderConfigurationOverviewProps) {
  const { t } = useTranslation('preOrder')

  function isFieldEditable(field: PreOrderConfigField) {
    return editable && EditableFields.includes(field)
  }

  const getOrderFormatName = useCallback(
    (orderFormat: OrderFormat) => {
      switch (orderFormat) {
        case OrderFormat.EatIn:
          return t('newOrder.orderFormatName', {
            name: t('common:orderFormat.eatIn'),
          })
        case OrderFormat.TakeAway:
          return t('newOrder.orderFormatName', {
            name: t('common:orderFormat.takeAway'),
          })
        case OrderFormat.Delivery:
          return t('newOrder.orderFormatName', {
            name: t('common:orderFormat.delivery'),
          })
        case OrderFormat.TableOrder:
          return t('common:orderFormat.tableOrder')
        default:
          return ''
      }
    },
    [t],
  )

  const derivedDataArray = useMemo(() => {
    const isGroupOrder =
      dataMapper[PreOrderConfigField.OrderMethod] === PreOrderMethod.Group

    const reducedDataArray = Object.entries(dataMapper).reduce<DataField[]>(
      (acc, [key, value]) => {
        const field = Number(key) as PreOrderConfigField
        switch (field) {
          case PreOrderConfigField.Restaurant:
            acc.push({
              field: PreOrderConfigField.Restaurant,
              icon: <StoreIcon />,
              label: t('newOrder.orderFrom'),
              value,
            })
            break
          case PreOrderConfigField.OrderDate:
            acc.push({
              field: PreOrderConfigField.OrderDate,
              icon: <CalendarIcon />,
              label: t('newOrder.orderBy'),
              value: moment(value).format('ddd, ll'),
            })
            break
          case PreOrderConfigField.OrderTime:
            acc.push({
              field: PreOrderConfigField.OrderTime,
              icon: <ClockIcon />,
              label: t('newOrder.orderAtAround'),
              value: getFormattedOrderTimeSlot(value),
            })
            break
          case PreOrderConfigField.OrderType:
            acc.push({
              field: PreOrderConfigField.OrderDate,
              icon: <OrderTypeIcon orderFormat={value} filled />,
              label: t('newOrder.orderAs'),
              value: getOrderFormatName(value),
            })
            break
          case PreOrderConfigField.OrderMethod:
            acc.push({
              field: PreOrderConfigField.OrderMethod,
              icon: isGroupOrder ? <UserGroupIcon /> : <NormalOrderIcon />,
              label: t('newOrder.orderMethod'),
              value: isGroupOrder
                ? t('newOrder.groupOrder')
                : t('newOrder.normalOrder'),
            })
            break
          case PreOrderConfigField.Customer:
            acc.push({
              field: PreOrderConfigField.Customer,
              icon: <HostIcon />,
              label: isGroupOrder ? t('newOrder.host') : t('newOrder.orderFor'),
              value,
            })
            break
          default:
            break
        }

        return acc
      },
      [],
    )

    return reducedDataArray
  }, [dataMapper, getOrderFormatName, t])

  return (
    <div className={styles.container}>
      {derivedDataArray.map(({ field, icon, label, value }) => (
        <div key={field} className={styles.dataField}>
          <PreOrderIconDataField key={field} icon={icon} label={label}>
            {value}
          </PreOrderIconDataField>
          {isFieldEditable(field) && (
            <Button
              variant="secondary"
              outlined
              className={styles.editButton}
              onClick={() => handleEdit?.(field)}
            >
              <EditIcon />
            </Button>
          )}
        </div>
      ))}
    </div>
  )
}

export { PreOrderConfigField }
export default PreOrderConfigurationOverview
