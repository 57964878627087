import { OutletListItem, OutletState } from '@ancon/wildcat-types'
import isOutletAcceptingPreOrders from '@ancon/wildcat-utils/outlet/isOutletAcceptingPreOrders'
import getOutletCurrentAvailabilityInfo from '@ancon/wildcat-utils/outlet/getOutletCurrentAvailabilityInfo'
import { OutletAvailabilityDay } from '@ancon/wildcat-utils/outlet/getOutletAvailabilityInfo'
import useTranslation from 'next-translate/useTranslation'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import clsx from 'clsx'
import MoonIcon from '@ancon/wildcat-ui/shared/icons/moon-fill.svg'

import styles from './OutletCardOutletStatus.module.scss'

interface OutletCardOutletStatusProps {
  outlet: OutletListItem
  inline?: boolean
  containerClassName?: string
}

export default function OutletCardOutletStatus({
  outlet,
  inline = false,
  containerClassName,
}: OutletCardOutletStatusProps) {
  const { t } = useTranslation('outlets')

  const { orderFormatSettings, state } = outlet

  function getOutletAvailabilityLabel() {
    const availabilityInfo = getOutletCurrentAvailabilityInfo(outlet)

    if (availabilityInfo?.state === OutletState.Open) {
      const { time, day } = availabilityInfo

      const openingTime =
        day === OutletAvailabilityDay.Tomorrow
          ? `${t('tomorrow')} ${time}`
          : time

      return t('itemLabel.opens', { time: openingTime })
    }

    return null
  }

  const availabilityLabel = getOutletAvailabilityLabel()
  const isAcceptingOfflineOrders =
    isOutletAcceptingPreOrders(orderFormatSettings)
  const isOutletOpen = state === OutletState.Open

  return (
    <div
      className={clsx(
        styles.outletStatusWrapper,
        {
          [styles.inline]: inline,
          [styles.hasContent]: !isOutletOpen || availabilityLabel,
        },
        containerClassName,
      )}
    >
      {!isOutletOpen &&
        (inline ? (
          <>
            <MoonIcon />
            <BodyText>
              {isAcceptingOfflineOrders ? t('acceptingPreOrders') : t('closed')}{' '}
              • {availabilityLabel ?? ''}
            </BodyText>
          </>
        ) : (
          <>
            <BodyText className={styles.outletStatusText}>
              {isAcceptingOfflineOrders ? t('acceptingPreOrders') : t('closed')}
            </BodyText>
            {availabilityLabel && (
              <div className={styles.outletAvailabilityTag}>
                <BodyText>{availabilityLabel}</BodyText>
              </div>
            )}
          </>
        ))}
    </div>
  )
}
