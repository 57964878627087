import { OutletListItem, OutletState } from '@ancon/wildcat-types'
import Image from 'next/image'
import clsx from 'clsx'
import OutletBannerPlaceholder from '@ancon/wildcat-ui/shared/static/placeholders/outlet-card-banner.svg'

import getImageUrl from '../../../app/utils/getImageUrl'

import OutletCardOutletStatus from './OutletCardOutletStatus'
import styles from './OutletCardBanner.module.scss'

interface OutletCardBannerProps {
  outlet: OutletListItem
}

export default function OutletCardBanner({ outlet }: OutletCardBannerProps) {
  const { name, imageId, logoId, modified, state } = outlet

  const bannerSrc = imageId ? getImageUrl(imageId, { modified }) : undefined
  const logoSrc = logoId
    ? getImageUrl(logoId)
    : '/images/outlet-logo-placeholder.jpg'

  const isOutletClosed = state !== OutletState.Open

  return (
    <div className={styles.bannerContainer}>
      <Image
        src={bannerSrc ?? OutletBannerPlaceholder}
        className={styles.bannerImage}
        width={300}
        height={150}
        alt={name}
      />
      <div
        className={clsx(styles.bannerOverlay, {
          [styles.outletClosed]: isOutletClosed,
        })}
      />
      <div className={styles.logoContainer}>
        <Image
          src={logoSrc}
          width={60}
          height={60}
          alt={name}
          className={styles.logoImage}
        />
        <OutletCardOutletStatus outlet={outlet} />
      </div>
    </div>
  )
}
